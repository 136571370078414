import React, { useEffect, useState } from "react";
import Relatorio from "../../../../components/v2/relatorio/Relatorio";
import { API_URL } from "../../../../consts";
import Axios from "axios";
import { toast } from "react-toastify";
import { Form } from "informed";
import { SelectInput } from "../../../../components";

const AcompanhamentoDeDiscrepancias = ({}) => {
  const [projetos, setProjetos] = useState([]);
  const [typeTable, setTypeTable] = useState();
  const [projetoId, setProjetoId] = useState();
  const [showTable, setShowTable] = useState(false);

  const columnsNota0e1000 = [
    {
      header: "Nome do projeto",
      accessor: "projeto__descricao",
      Cell: (d) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>{d.value}</strong>
        </div>
      ),
    },
    {
      header: "Nome do corretor",
      accessor: "corretor_name",
      Cell: (d) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>{d.value}</strong>
        </div>
      ),
    },
    {
      header: "Total de correções",
      accessor: "num_total",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com nota 0",
      accessor: "num_correcoes_notas0",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com nota 1000",
      accessor: "num_correcoes_notas1000",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "% de correções com nota 0",
      accessor: "porcentagem_de_correcoes_com_nota_0",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "% de correções com nota 1000",
      accessor: "porcentagem_de_correcoes_com_nota_1000",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções anuladas",
      accessor: "num_correcoes_anuladas",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Alerta",
      Cell: (d) => (
        <strong className="column-left">
          <i class="fas fa-exclamation-triangle"></i>
        </strong>
      ),
    },
  ];

  const columnsCriterioAnulacao = [
    {
      header: "Nome do projeto",
      accessor: "projeto__descricao",
      Cell: (d) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>{d.value}</strong>
        </div>
      ),
    },
    {
      header: "Nome do corretor",
      accessor: "corretor_name",
      Cell: (d) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>{d.value}</strong>
        </div>
      ),
    },
    {
      header: "Total de correções",
      accessor: "num_total",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Ilegibilidade",
      accessor: "ilegibilidade",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Em branco",
      accessor: "em_branco",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Identificada",
      accessor: "identificada",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Texto insuficiente",
      accessor: "texto_insuficiente",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Texto padronizado/plágio",
      accessor: "texto_padronizado_plagio",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Resposta inadequada",
      accessor: "resposta_inadequada",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Fuga ao tema",
      accessor: "fuga_ao_tema",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções anuladas",
      accessor: "num_correcoes_anuladas",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Alerta",
      Cell: (d) => (
        <strong className="column-left">
          <i class="fas fa-exclamation-triangle"></i>
        </strong>
      ),
    },
  ];

  const columnsDiscrepanciaCorretorNotalFinal = [
    {
      header: "Nome do projeto",
      accessor: "projeto__descricao",
      Cell: (d) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>{d.value}</strong>
        </div>
      ),
    },
    {
      header: "Nome do corretor",
      accessor: "name_corretor",
      Cell: (d) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>{d.value}</strong>
        </div>
      ),
    },
    {
      header: "Correções corrigidas",
      accessor: "nr_correcoes_corrigidas",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com discrepância",
      accessor: "nr_correcoes_com_discrepancia",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "% discrepância",
      accessor: "percentual_correcoes_com_discrepancia",
      Cell: (d) => <strong className="column-left">{d.value}%</strong>,
    },
    {
      header: "Correções não aproveitadas",
      accessor: "nr_correcoes_com_discrepancia_nao_aproveitadas",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "% não aproveitadas",
      accessor: "percentual_correcoes_nao_aproveitadas",
      Cell: (d) => <strong className="column-left">{d.value}%</strong>,
    },
    {
      header: "Alerta",
      accessor: "alerta",
      Cell: (d) => (
        <strong className="column-left">
          {d.value && <i class="fas fa-exclamation-triangle" style={{color: 'red'}}></i>}          
        </strong>
      ),
    },
  ];

  const columnsDiscrepanciaCorretorCompetencia = [
    {
      header: "Nome do projeto",
      accessor: "projeto__descricao",
      Cell: (d) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>{d.value}</strong>
        </div>
      ),
    },
    {
      header: "Nome do corretor",
      accessor: "name_corretor",
      Cell: (d) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>{d.value}</strong>
        </div>
      ),
    },
    {
      header: "Correções corrigidas",
      accessor: "nr_correcoes_corrigidas",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com discrepância",
      accessor: "nr_correcoes_com_discrepancia",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções não aproveitadas",
      accessor: "nr_correcoes_com_discrepancia_nao_aproveitadas",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com discrepância C1",
      accessor: "nr_correcoes_com_discrepancia_por_c1",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com discrepância C2",
      accessor: "nr_correcoes_com_discrepancia_por_c2",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com discrepância C3",
      accessor: "nr_correcoes_com_discrepancia_por_c3",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com discrepância C4",
      accessor: "nr_correcoes_com_discrepancia_por_c4",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Correções com discrepância C5",
      accessor: "nr_correcoes_com_discrepancia_por_c5",
      Cell: (d) => <strong className="column-left">{d.value}</strong>,
    },
    {
      header: "Alerta",
      Cell: (d) => (
        <strong className="column-left">
          <i class="fas fa-exclamation-triangle"></i>
        </strong>
      ),
    },
  ];

  const optionTable = {
    "Notas 1000 e 0 por corretor": {
      endPoint: `relatorio/v2/acompanhamento-situacoes/notas1000e0?projeto_id=${projetoId}`,
      value: columnsNota0e1000,
    },
    "Critérios de anulação": {
      endPoint: `relatorio/v2/acompanhamento-situacoes/criterios_anulacao?projeto_id=${projetoId}`,
      value: columnsCriterioAnulacao,
    },
    "Discrepância por corretor nota final": {
      endPoint: `relatorio/v2/acompanhamento-situacoes/discrepancias_nota_final?projeto_id=${projetoId}`,
      value: columnsDiscrepanciaCorretorNotalFinal,
    },
  };

  const fetchProjetos = async () => {
    try {
      const res = await Axios.get(`${API_URL}/projeto/all`);
      setProjetos(
        res.data.map((x) => ({
          value: x.id,
          label: x.descricao,
        }))
      );
    } catch (err) {
      toast.error("Erro ao buscar projetos, tente novamente.");
    }
  };

  useEffect(() => {
    fetchProjetos();
  }, []);

  return (
    <>
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <div className="page-container-border position-relative">
          <section className="mt-3">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <h1
                  className="text-title"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginLeft: "40px",
                  }}
                >
                  Acompanhamento de Discrepâncias
                </h1>
              </div>
            </div>
            <Form
              onSubmit={(values) => {
                setProjetoId(values.projeto);
                setTypeTable(values.optionTable);
                setShowTable(true);
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: "40px",
              }}
            >
              <SelectInput
                label="Projeto:"
                field="projeto"
                options={projetos}
                className="col-md-3"
                required
              />
              <SelectInput
                label="Tipo de relatório:"
                field="optionTable"
                options={Object.keys(optionTable).map((key) => ({
                  label: key,
                  value: key,
                }))}
                className="col-md-3 mt-2"
                required
              />
              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginTop: "1rem" }}
              >
                APLICAR FILTROS
              </button>
            </Form>
            <>
              {showTable && optionTable.hasOwnProperty(typeTable) && (
                <Relatorio
                  dataUrl={optionTable[typeTable].endPoint}
                  title={typeTable}
                  columns={optionTable[typeTable].value}
                  defaultPageSize={10}
                  showPageSizeOptions={true}
                  showPagination={true}
                  hideForm
                />
              )}
            </>
          </section>
        </div>
      </div>
      <br />
    </>
  );
};

export default AcompanhamentoDeDiscrepancias;
