const NAVBAR = [
  {
    id: "_menu_painel",
    nome: "Início",
    link: "/",
    show: true,
    permission: "",
    feature: "",
  },
  {
    id: "_menu_lista_correcoes",
    nome: "Correções",
    link: "/correcoes",
    show: true,
    permission: "ver_menu_correcoes",
    feature: "",
  },
  {
    id: "_menu_ocorrencias",
    nome: "Ocorrências",
    link: "/tickets?pending=true",
    show: true,
    permission: "ver_menu_ocorrencias",
    feature: "ocorrencia",
  },
  {
    id: "_menu_recursos",
    nome: "Recursos",
    link: "/recursos",
    show: true,
    permission: "view_recurso",
    feature: "",
  },
  {
    id: "_menu_drop_avaliadores",
    nome: "Avaliadores",
    link: "/v2/avaliadores",
    show: true,
    permission: "ver_menu_avaliadores",
    feature: "ver_menu_avaliadores",
  },
  {
    id: "_menu_drop_supervisores",
    nome: "Supervisores",
    link: "/supervisores",
    show: true,
    permission: "ver_menu_supervisores",
    feature: "ver_menu_supervisores",
  },
  {
    id: "_menu_banco_redacoes",
    nome: "Banco de Redações",
    link: "/banco-redacoes",
    show: true,
    permission: "ver_menu_redacoes",
    feature: "banco_redacoes",
  },
  {
    id: "_alertas",
    nome: "Configurar Alertas",
    link: "/configurar-alertas",
    show: true,
    permission: "configurar_alertas",
    feature: "configurar_alertas",
  },
  {
    id: "_menu_resumo_correcoes",
    nome: "Resumo",
    link: "/resumo-correcoes",
    show: true,
    permission: "ver_menu_resumo_correcoes",
    feature: "resumo_correcoes",
  },
  {
    id: "_menu_avisos",
    nome: "Avisos",
    link: "/avisos",
    show: true,
    permission: "ver_menu_avisos",
    feature: "",
  },
  {
    id: "_menu_drop_relatorios",
    nome: "Relatórios",
    link: "",
    show: true,
    permission: "ver_menu_relatorios",
    feature: "relatorios",
    class: "dropDownRelatorios",
    dropdown: [
      {
        id: "_menu_acompanhamento_geral",
        nome: "Acompanhamento Geral",
        external: false,
        link: "/relatorios/v2/acompanhamento-geral",
        permission: "ver_relatorio_acompanhamento_geral_geral",
        feature: "",
      },
      {
        id: "_menu_notas_geral",
        nome: "Relatório de Notas",
        external: false,
        link: "/relatorios/v2/relatorio-notas",
        permission: "ver_relatorio_acompanhamento_geral_notas",
        feature: "",
      },
      {
        id: "_menu_relatorio_geral",
        nome: "Andamento da Correção",
        external: false,
        link: "/relatorios/painel-de-acompanhamento",
        permission: "ver_andamento_da_correcao",
        feature: "ver_andamento_da_correcao",
      },
      {
        id: "_menu_relatorios_geral",
        nome: "Relatório Geral",
        permission: "",
        feature: "",
        class: "dropDownRelatorios",
        subdropdown: [
          {
            id: "_menu_relatorio_coordenador_geral",
            nome: "POLOS",
            external: false,
            link: "/relatorios/v2/relatorio-geral-polo",
            permission: "ver_relatorio_geral",
            feature: "ver_relatorio_geral",
          },
          {
            id: "_menu_relatorio_polo",
            nome: "TIMES",
            external: false,
            link: "/relatorios/v2/relatorio-geral-time",
            permission: "ver_relatorio_geral_polo",
            feature: "ver_relatorio_geral_polo",
          },
          {
            id: "_menu_relatorio_time",
            nome: "AVALIADORES",
            external: false,
            link: "/relatorios/v2/relatorio-geral-avaliador",
            permission: "ver_relatorio_geral_time",
            feature: "ver_relatorio_geral_time",
          },
        ],
      },
      //  distribuicao de notas
      {
        id: "_menu_relatorio_distribuicao_notas",
        nome: "Distribuição de Notas",
        permission: "",
        feature: "",
        subdropdown: [
          {
            id: "_menu_relatorio_notas",
            nome: "POLOS",
            external: false,
            link: "/relatorios/v2/distribuicao-notas-competencia-polo",
            permission: "ver_relatorio_distribuicao_notas_competencia_polo",
            feature: "ver_relatorio_distribuicao_notas_competencia_polo",
          },
          {
            id: "_menu_relatorio_global",
            nome: "TIMES",
            external: false,
            link: "/relatorios/v2/distribuicao-notas-competencia-time",
            permission: "ver_relatorio_distribuicao_notas_competencia_time",
            feature: "ver_relatorio_distribuicao_notas_competencia_time",
          },
          {
            id: "_menu_relatorio_notas_time",
            nome: "AVALIADORES",
            external: false,
            link: "/relatorios/v2/distribuicao-notas-competencia-avaliadores",
            permission: "ver_relatorio_distribuicao_notas_competencia_geral",
            feature: "ver_relatorio_distribuicao_notas_competencia_geral",
          },
        ],
      },
      // distribuicao de notas por situação
      {
        id: "_menu_relatorio_distribuicao_situacao",
        nome: "Distribuição de Situações",
        permission: "",
        feature: "",
        subdropdown: [
          {
            id: "_menu_relatorio_situacao_geral",
            nome: "POLOS",
            external: false,
            link: "/relatorios/v2/distribuicao-notas-situacao-polo",
            permission: "ver_relatorio_distribuicao_notas_situacao_geral",
            feature: "ver_relatorio_distribuicao_notas_situacao_geral",
          },
          {
            id: "_menu_relatorio_situacao_polo",
            nome: "TIMES",
            external: false,
            link: "/relatorios/v2/distribuicao-notas-situacao-time",
            permission: "ver_relatorio_distribuicao_notas_situacao_polo",
            feature: "ver_relatorio_distribuicao_notas_situacao_polo",
          },
          {
            id: "_menu_relatorio_situacao_time",
            nome: "AVALIADORES",
            external: false,
            link: "/relatorios/v2/distribuicao-notas-situacao-avaliadores",
            permission: "ver_relatorio_distribuicao_notas_situacao_time",
            feature: "ver_relatorio_distribuicao_notas_situacao_time",
          },
        ],
      },
      // aproveitamento de notas
      {
        id: "_menu_relatorio_aproveitamento",
        nome: "Aproveitamento de Notas",
        permission: "",
        feature: "",
        subdropdown: [
          {
            id: "_menu_relatorio_aproveitamento_geral",
            nome: "PROJETOS",
            external: false,
            link: "/relatorios/v2/aproveitamento-polo",
            permission: "ver_relatorio_aproveitamento_geral",
            feature: "ver_relatorio_aproveitamento_geral",
          },
          {
            id: "_menu_relatorio_aproveitamento_polo",
            nome: "TIMES",
            external: false,
            link: "/relatorios/v2/aproveitamento-time",
            permission: "ver_relatorio_aproveitamento_polo",
            feature: "ver_relatorio_aproveitamento_polo",
          },
          {
            id: "_menu_relatorio_aproveitamento_time",
            nome: "AVALIADORES",
            external: false,
            link: "/relatorios/v2/aproveitamento-avaliadores",
            permission: "ver_relatorio_aproveitamento_time",
            feature: "ver_relatorio_aproveitamento_time",
          },
          {
            id: "_menu_relatorio_aproveitamento_avaliador",
            nome: "INDIVIDUAL",
            external: false,
            link: "/relatorios/v2/aproveitamento-individual",
            permission: "ver_relatorio_aproveitamento_avaliador",
            feature: "ver_relatorio_aproveitamento_avaliador",
          },
        ],
      },
      // padrao ouro
      {
        id: "_menu_relatorio_padrao_ouro",
        nome: "Padrão Ouro",
        permission: "",
        feature: "",
        subdropdown: [
          {
            id: "_menu_relatorio_padrao_ouro_geral",
            nome: "POLOS",
            external: false,
            link: "/relatorios/v2/padrao-ouro-polo",
            permission: "ver_relatorio_padrao_ouro_geral",
            feature: "ver_relatorio_padrao_ouro_geral",
          },
          {
            id: "_menu_relatorio_padrao_ouro_polo",
            nome: "TIMES",
            external: false,
            link: "/relatorios/v2/padrao-ouro-time",
            permission: "ver_relatorio_padrao_ouro_polo",
            feature: "ver_relatorio_padrao_ouro_polo",
          },
          {
            id: "_menu_relatorio_padrao_ouro_time",
            nome: "AVALIADORES",
            external: false,
            link: "/relatorios/v2/padrao-ouro-avaliadores",
            permission: "ver_relatorio_padrao_ouro_time",
            feature: "ver_relatorio_padrao_ouro_time",
          },
          {
            id: "_menu_relatorio_padrao_ouro_avaliador",
            nome: "INDIVIDUAL",
            external: false,
            link: "/relatorios/v2/padrao-ouro-individual",
            permission: "ver_relatorio_padrao_ouro_avaliador",
            feature: "ver_relatorio_padrao_ouro_avaliador",
          },
        ],
      },
      // terceira correção
      {
        id: "_menu_relatorio_terceira_correcao",
        nome: "Terceira Correção",
        permission: "",
        feature: "",
        subdropdown: [
          {
            id: "_menu_relatorio_terceira_correcao_geral",
            nome: "POLOS",
            external: false,
            link: "/relatorios/v2/terceira-correcao-geral",
            permission: "ver_relatorio_terceira_correcao_geral",
            feature: "ver_relatorio_terceira_correcao_geral",
          },
          {
            id: "_menu_relatorio_terceira_correcao_por_polo",
            nome: "TIMES",
            external: false,
            link: "/relatorios/v2/terceira-correcao-polo",
            permission: "ver_relatorio_terceira_correcao_polo",
            feature: "ver_relatorio_terceira_correcao_polo",
          },
          {
            id: "_menu_relatorio_terceira_correcao_por_time",
            nome: "AVALIADORES",
            external: false,
            link: "/relatorios/v2/terceira-correcao-time",
            permission: "ver_relatorio_terceira_correcao_time",
            feature: "ver_relatorio_terceira_correcao_time",
          },
          {
            id: "_menu_relatorio_terceira_correcao_por_avaliador",
            nome: "INDIVIDUAL",
            external: false,
            link: "/relatorios/v2/terceira-correcao-avaliador",
            permission: "ver_relatorio_terceira_correcao_avaliador",
            feature: "ver_relatorio_terceira_correcao_avaliador",
          },
        ],
      },
      {
        id: "_menu_relatorio_quarta_correcao",
        nome: "Quarta Correção",
        permission: "",
        feature: "",
        subdropdown: [
          {
            id: "_menu_relatorio_quarta_correcao_geral",
            nome: "POLOS",
            external: false,
            link: "/relatorios/v2/quarta-correcao-geral",
            permission: "ver_relatorio_quarta_correcao_geral",
            feature: "ver_relatorio_quarta_correcao_geral",
          },
          {
            id: "_menu_relatorio_quarta_correcao_por_polo",
            nome: "SUPERVISORES",
            external: false,
            link: "/relatorios/v2/quarta-correcao-por-polo",
            permission: "ver_relatorio_quarta_correcao_polo",
            feature: "ver_relatorio_quarta_correcao_polo",
          },
        ],
      },
      {
        id: "_menu_relatorio_panorama_ocorrencias",
        nome: "PANORAMA GERAL DE OCORRÊNCIAS",
        permission: "",
        feature: "",
        subdropdown: [
          {
            id: "_menu_relatorio_panorama_ocorrencias_geral",
            nome: "POLOS",
            external: false,
            link: "/relatorios/v2/panorama-geral-ocorrencias",
            permission: "ver_relatorio_panorama_geral_ocorrencias",
            feature: "ver_relatorio_panorama_geral_ocorrencias",
          },
          {
            id: "_menu_relatorio_panorama_ocorrencias_supervisores",
            nome: "SUPERVISORES",
            external: false,
            link: "/relatorios/v2/panorama-geral-ocorrencias",
            permission: "ver_relatorio_panorama_geral_ocorrencias_supervisor",
            feature: "ver_relatorio_panorama_geral_ocorrencias",
          },
        ],
      },
      {
        id: "_menu_relatorio_auditoria",
        nome: "AUDITORIA",
        external: false,
        link: "/relatorios/v2/acompanhamento-auditoria",
        permission: "ver_relatorio_acompanhamento_auditoria",
        feature: "ver_relatorio_acompanhamento_auditoria",
      },
      {
        id: "_menu_relatorio_pre_teste_lista_avaliadores",
        nome: "PRÉ TESTE - AVALIADORES",
        external: false,
        link: "/relatorios/pre-teste-avaliadores",
        permission: "ver_relatorio_pre_teste_avaliadores",
        feature: "ver_relatorio_pre_teste_avaliadores",
      },
      {
        id: "_menu_relatorio_pre_teste_avaliadores",
        nome: "PRÉ TESTE NOTA POR AVALIADOR",
        external: false,
        link: "/relatorios/pre-teste-nota-avaliador",
        permission: "ver_relatorio_pre_teste_avaliadores",
        feature: "ver_relatorio_pre_teste_avaliadores",
      },
      {
        id: "_menu_extrato",
        nome: "Extrato Corretor",
        link: "/relatorios/extrato",
        external: false,
        permission: "ver_relatorio_extrato",
        feature: "ver_relatorio_extrato",
      },
    ],
  },
  {
    id: "_menu_triagem_fea",
    nome: "Triagem FEA",
    link: "/triagem-fea",
    show: true,
    permission: "participar_triagem_fea",
    feature: "triagem_fea",
  },
  {
    id: "_menu_admin",
    nome: "Importações",
    link: "/admin",
    show: true,
    permission: "ver_menu_admin",
  },
  {
    id: "_menu_redacoes_desempenho",
    nome: "Redações Desempenho",
    link: "/admin/redacoes-desempenho",
    show: true,
    permission: "importar_redacoes_desempenho",
    feature: "importar_redacoes_desempenho",
  },
  {
    id: "_menu_acompanhamento",
    nome: "Acompanha- mento",
    // link: "",
    show: true,
    permission: "pode_visualizar_acompanhamento",
    feature: "",
    class: "dropDownAcompanhamento",
    dropdown: [
      {
        id: "_acompanhamento_geral",
        nome: "Acompanhamento Geral",
        external: false,
        link: "/acompanhamento/geral",
        class: "dropDownAcompanhamento",
        permission: "", //TODO: validar regras de permissao e regra de negocio
        feature: "",
      },
      {
        id: "_acompanhamento_de_situacoes",
        nome: "Acompanhamento de Discrepâncias",
        external: false,
        link: "/acompanhamento/situacoes",
        class: "dropDownAcompanhamento",
        permission: "pode_visualizar_acompanhamento_situacoes",  //TODO: validar regras de permissao e regra de negocio
        feature: "",
      }
    ],
  },
  {
    id: "_menu_analise_resultados",
    nome: "Analise de Resultados",
    // link: "",
    show: true,
    permission: "pode_visualizar_analise_de_resultados",
    feature: "",
    class: "dropDownAnaliseResultados",
    dropdown: [
      {
        id: "_analise_notas",
        nome: "Analise de Notas",
        external: false,
        link: "/analise_resultados/notas",
        class: "dropDownAnaliseResultados",
        permission: "pode_visualizar_analise_de_resultados_nota", //TODO: validar regras de permissao e regra de negocio
        feature: "",
      },
      {
        id: "_analise_notas_competencia",
        nome: "Analise de Notas por competência",
        external: false,
        link: "/analise_resultados/notas-competencia",
        class: "dropDownAnaliseResultados",
        permission: "pode_visualizar_analise_de_resultados_nota_competencia", //TODO: validar regras de permissao e regra de negocio
        feature: "",
      },
      {
        id: "_analise_apontamento",
        nome: "Analise de Apontamento",
        external: false,
        link: "/analise_resultados/apontameto",
        class: "dropDownAnaliseResultados",
        permission: "pode_visualizar_analise_de_resultados_apontamento", //TODO: validar regras de permissao e regra de negocio
        feature: "",
      },
    ],
  },
  {
    id: "_configurar-projeto",
    nome: "Configurar Projeto",
    link: "/list-projects",
    show: true,
  },
];

export default NAVBAR;
